import React from 'react'

import { Section } from 'layout'
import { Link } from 'core'
import { ncfddLogoOnWhite } from 'images'

import css from './style.scss'

const RegistrationHeader = () => (
  <div className={css.header}>
    <Section>
      <Link to='/'>
        <img
          src={ncfddLogoOnWhite}
          alt='NCFDD'
          className={css.logo}
        />
      </Link>
    </Section>
  </div>
)

export default RegistrationHeader
