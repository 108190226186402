import React, { PropTypes } from 'react'
import { get } from 'lodash'

import { load, schemas, NotFoundError, ForbiddenError } from 'api'
import { Header } from 'header'
import { applyHOCs, selectSlug } from 'helpers'
import { Footer } from 'footer'
import { PageHead, NotFoundPage, ForbiddenPage } from 'page'

import { BannerNotice } from 'core'
import CMSPage from './CMSPage'
import { connect } from 'react-redux'
import { currentUserSelector } from 'auth'
import { shouldDisplayBlock } from 'core/components/PageBlockRenderer/PageBlockRenderer'

const isTransparent = (headerBlockType) => {
  return headerBlockType === 'HeaderFSP' || headerBlockType === 'HeaderPrimary'
}

export const CMSRoute = ({ page, loadStatus, dynamicConditions }) => {
  let content = null
  let showFooter = true

  if (page) {
    content = <CMSPage {...page} />
  } else if (loadStatus.loading) {
    content = null
    showFooter = false
  } else if (loadStatus.page.error instanceof NotFoundError) {
    content = <NotFoundPage />
  } else if (loadStatus.page.error instanceof ForbiddenError) {
    content = <ForbiddenPage />
  }

  return (
    <div>
      {page && <PageHead title={get(page, 'title')} />}
      <Header transparent={shouldDisplayBlock(get(page, 'header_block'), dynamicConditions) && isTransparent(get(page, 'header_block.block_type'))} />
      {page && page.published_at === null &&
        <BannerNotice>
          This unpublished page preview is only visible to administrators.
        </BannerNotice>}
      {content}
      {showFooter && <Footer />}
    </div>
  )
}

CMSRoute.propTypes = {
  page: PropTypes.shape({
    published_at: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),
    header_block: PropTypes.object
  }),
  slug: PropTypes.string.isRequired
}

export default applyHOCs([
  load({
    page: {
      urlSelector: (state, props) => {
        return `/v2/cms_pages/slug/${props.slug}`
      },
      schema: schemas.cmsPage
    }
  }),
  connect(state => ({
    dynamicConditions: currentUserSelector(state)?.dynamicConditions
  })),
  selectSlug()
])(CMSRoute)
