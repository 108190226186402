import React from 'react'

import { Card } from 'core'
import { Combo, ComboFirst, ComboLast } from 'layout'

import { CMSSection } from '../components'

import css from './style.scss'

const Pricing = (props) => {
  const { title, subtitle, items } = props
  return (
    <CMSSection
      title={title}
      subtitle={subtitle}
    >
      <div className={css.wrapper}>
        {
          items.map((item, index) => {
            return (
              <Card key={index} spacing spacingMobile>
                <div className={css.cardWrapper}>
                  <Combo middle reverse mobileReset wide>
                    <ComboFirst>
                      <p className={css.name}>{item.item_name}</p>
                      {item.item_details?.map((d, i) => (
                        <p key={i} className={css.detail}>{d}</p>
                      ))}
                    </ComboFirst>
                    <ComboLast>
                      <p className={css.price}>
                        {item.item_discounted_price && (
                          <span className={css.originalPrice}>{item.item_price}</span>
                        )}
                        {item.item_discounted_price || item.item_price}
                      </p>
                      <p className={css.unit}>{item.item_unit}</p>
                    </ComboLast>
                  </Combo>
                </div>
              </Card>
            )
          })
        }
      </div>
    </CMSSection>
  )
}

Pricing.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  items: React.PropTypes.arrayOf(React.PropTypes.shape({
    item_name: React.PropTypes.string.isRequired,
    item_details: React.PropTypes.arrayOf(React.PropTypes.string).isRequired,
    item_price: React.PropTypes.string.isRequired,
    item_unit: React.PropTypes.string.isRequired,
    item_discounted_price: React.PropTypes.string
  })).isRequired
}

export default Pricing
