import React from 'react'
import * as blockComponents from 'page-blocks'
import { connect } from 'react-redux'
import { currentUserSelector } from 'auth'
import { isEligible } from 'dynamic-conditions/isEligible'

export const shouldDisplayBlock = (block, dynamicConditions) => {
  if (block?.block_content?.display_conditions?.length > 0) {
    const shouldDisplay = isEligible({
      conditions: block.block_content.display_conditions,
      userDynamicConditions: dynamicConditions
    })
    if (!shouldDisplay) {
      return false
    }
  }
  return true
}

class PageBlockRenderer extends React.Component {
  componentDidUpdate () {
    if (!this.hasScrolledToAnchor) {
      this.props.blocks.forEach((block) => {
        if (block?.block_content?.anchor_tag) {
          const anchor = document.getElementById(block.block_content.anchor_tag)
          if (anchor && window.location.hash === `#${block.block_content.anchor_tag}`) {
            this.hasScrolledToAnchor = true
            anchor.scrollIntoView()
          }
        }
      })
    }
  }

  render () {
    const { blocks, sessionSale, dynamicConditions } = this.props
    if (!blocks?.length) {
      return null
    }
    return (
      <div>
        {
            blocks.map((block, index) => {
              const BlockComponent = blockComponents[block.block_type]
              if (!BlockComponent) {
                return null
              }
              const shouldDisplay = shouldDisplayBlock(block, dynamicConditions)
              if (!shouldDisplay) {
                return null
              }
              if (block.block_content?.anchor_tag) {
                return (
                  <div id={block.block_content.anchor_tag} key={index}>
                    <BlockComponent {...block.block_content} session_sale={sessionSale} key={index} />
                  </div>
                )
              }
              return <BlockComponent {...block.block_content} session_sale={sessionSale} key={index} />
            })
          }
      </div>
    )
  }
}

export default connect(
  (state) => ({
    dynamicConditions: currentUserSelector(state)?.dynamicConditions
  })
)(PageBlockRenderer)
