import React, { PropTypes } from 'react'
import moment from 'moment'

import { load, schemas } from 'api'
import { BannerNotice } from 'core'
import { applyHOCs, selectSlug } from 'helpers'
import { page, pageLoadSelectors, PageHead } from 'page'
import { ArticleBody, HeaderArticle } from 'page-blocks'

import css from './style.scss'

const PostDetail = (props) => {
  const { post } = props
  let { author } = post

  if (post.type === 'monday_motivator') {
    author = null
  }

  return (
    <div>
      <PageHead title={post.title} />
      {post && (post.publish_date === null || moment().isBefore(post.publish_date)) &&
        <BannerNotice>
          This unpublished post preview is only visible to administrators.
        </BannerNotice>}
      <div className={css.wrapper}>
        <HeaderArticle
          title={post.title}
          date={post.publish_date}
          author={author}
          type={post.type}
        />
        <ArticleBody body={post.body} />
      </div>
    </div>
  )
}

PostDetail.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    date: PropTypes.string,
    author: React.PropTypes.shape({
      name: React.PropTypes.string.isRequired,
      avatar_url: React.PropTypes.string
    })
  })
}

export default applyHOCs([
  page({
    noPageHead: true,
    ...pageLoadSelectors('post')
  }),
  load({
    post: {
      urlSelector: (state, props) => {
        return `/v2/posts/slug/${props.slug}`
      },
      schema: schemas.post
    }
  }),
  selectSlug()
])(PostDetail)
