import React, { PropTypes } from 'react'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { every } from 'lodash'

import { load, schemas } from 'api'
import { currentUserFlagsSelector, isAuthenticatedSelector } from 'auth'
import { writeNowUrl } from 'config'
import { applyHOCs } from 'helpers'

import { Notice } from '../../../core/components/Notice'
import RegisterButton from '../components/RegisterButton'
import SessionSaleHeader from '../components/SessionSaleHeader'
import SaleStateText from '../components/SaleStateText'

import { getDateState, participatingInSession } from '../helpers'
import { openLoginModal } from 'login-modal'
import { getRegistrationTimingTexts } from '../fsp/FSPHeader'

export const getRegistrationHeadingText = ({ sessionSale, openRegistrationState, participating }) => {
  if (participating) {
    return sessionSale.title
  } else if (openRegistrationState === 'after') {
    return `Registration for the ${sessionSale.title} has finished`
  } else if (sessionSale.registration_status === 'none' && sessionSale.num_reservations_available === 0) {
    return `The ${sessionSale.title} is currently at full capacity`
  } else {
    return `Registration for the ${sessionSale.title}`
  }
}

const canUserRegister = (sessionSale, userFlags) => {
  return every(sessionSale.flags, (required, flag) => (!required || userFlags[flag]))
}

export const GenericRegisterButton = ({ sessionSale, openRegistrationState, participating, userFlags, openLoginModal, isAuthenticated }) => {
  if (openRegistrationState === 'after') {
    // Once open registration has closed, nobody can register
    return null
  }

  if (participating) {
    return (
      <RegisterButton onClick={() => window.open(writeNowUrl)}>
        Go to WriteNow
      </RegisterButton>
    )
  }

  if (sessionSale.registration_status === 'reserved') {
    return (
      <RegisterButton disabled>
        You are registered
      </RegisterButton>
    )
  } else if (sessionSale.registration_status === 'waitlisted') {
    return (
      <RegisterButton disabled>
        You are on the wait list
      </RegisterButton>
    )
  } else if (isAuthenticated && !canUserRegister(sessionSale, userFlags)) {
    return (
      <Notice>
        Based on your profile, you do not meet the requirements to join this session. Please email Bootcamp@ncfdd.org with any questions.
      </Notice>
    )
  } else if (sessionSale.num_reservations_available === 0) {
    return (
      <RegisterButton
        onClick={() => browserHistory.push(`/sessions/${sessionSale.slug}/join-waitlist`)}
      >
        Join the waitlist
      </RegisterButton>
    )
  } else {
    return (
      <RegisterButton
        onClick={() => {
          if (!canUserRegister(sessionSale, userFlags)) {
            openLoginModal()
          } else {
            browserHistory.push(`/sessions/${sessionSale.slug}/reserve`)
          }
        }}
      >
        Register now
      </RegisterButton>
    )
  }
}

export const GenericHeader = ({ sessionSale, activeGroups, userFlags, openLoginModal, isAuthenticated }) => {
  const participating = participatingInSession(sessionSale, activeGroups)

  const openRegistrationState = getDateState(
    sessionSale.open_registration_start_at,
    sessionSale.open_registration_end_at
  )

  const headingText = getRegistrationHeadingText({
    sessionSale,
    openRegistrationState,
    participating
  })

  const registerButton = (
    <GenericRegisterButton
      isAuthenticated={isAuthenticated}
      sessionSale={sessionSale}
      openLoginModal={openLoginModal}
      openRegistrationState={openRegistrationState}
      participating={participating}
      userFlags={userFlags}
    />
  )

  const registrationTimingTexts = getRegistrationTimingTexts({
    sessionSale,
    openRegistrationState,
    participating
  })

  return (
    <SessionSaleHeader sessionSale={sessionSale} title={headingText}>
      {registerButton}
      {registrationTimingTexts && registrationTimingTexts?.map(t => (
        <SaleStateText key={t}>{t}</SaleStateText>
      ))}
    </SessionSaleHeader>
  )
}

GenericHeader.propTypes = {
  isIAH: PropTypes.bool,
  sessionSale: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    num_reservations_available: PropTypes.number.isRequired,
    num_institution_reservations_available: PropTypes.number,
    registration_status: PropTypes.string.isRequired,
    open_registration_start_at: PropTypes.string.isRequired,
    open_registration_end_at: PropTypes.string.isRequired,
    advertised_start_date: PropTypes.string.isRequired,
    advertised_end_date: PropTypes.string.isRequired
  }).isRequired,
  activeGroups: PropTypes.arrayOf(PropTypes.shape({
    session: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }))
}

export default applyHOCs([
  connect(
    (state) => ({
      userFlags: currentUserFlagsSelector(state),
      isAuthenticated: isAuthenticatedSelector(state)
    }),
    (dispatch) => ({
      openLoginModal: () => dispatch(openLoginModal({ redirectToDashboard: false, refresh: true }))
    })
  ),
  load({
    activeGroups: {
      urlSelector: () => '/v2/users/current/groups',
      schema: [schemas.group],
      skipSelector: (state) => {
        return !isAuthenticatedSelector(state)
      }
    }
  })
])(GenericHeader)
