export const isEligible = ({ conditions, userDynamicConditions }) => {
  if (!conditions?.length) {
    return true
  }

  const sessionState = userDynamicConditions ? 'logged_in' : 'logged_out'
  return !!conditions?.find(condition => {
    if (condition.title &&
      condition.title !== 'any' &&
      condition.title !== userDynamicConditions?.title
    ) {
      return false
    }
    if (condition.member_type && condition.member_type !== 'any' && !userDynamicConditions?.member_types?.includes?.(condition.member_type)) {
      return false
    }
    if (condition.associated_institution &&
      condition.associated_institution !== 'any' &&
      condition.associated_institution !== userDynamicConditions?.associated_institution
    ) {
      return false
    }
    if (condition.fsp_alumni_status &&
      condition.fsp_alumni_status !== 'any' &&
      condition.fsp_alumni_status !== userDynamicConditions?.fsp_alumni_status
    ) {
      return false
    }
    if (condition.session_state &&
      condition.session_state !== 'any' &&
      condition.session_state !== sessionState
    ) {
      return false
    }
    return true
  })
}
