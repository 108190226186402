import React from 'react'

import moment from 'moment'

import { formatDate } from 'helpers'

import { Checklist } from '../Checklist'
import { connect } from 'react-redux'
import { currentUserSelector } from 'auth'

export const SessionSaleDates = ({ session_sale: sessionSale, currentDate, user }) => {
  if (!sessionSale) return null

  const checklistItems = []

  if (sessionSale.eligible_discount_windows?.length > 0) {
    sessionSale.eligible_discount_windows.forEach((discountWindow) => {
      const endText = currentDate.isBefore(discountWindow.end_at) ? 'Ends' : 'Ended'
      checklistItems.push({
        checklist_item: `<p>${discountWindow.name} Discount ${endText}: ${formatDate(discountWindow.end_at)}</p>`
      })
    })
  }

  checklistItems.push({
    checklist_item: `<p>${sessionSale.title} Registration Closes: ${formatDate(sessionSale.open_registration_end_at)}</p>`
  })

  return (
    <Checklist
      title='Important Dates to Remember'
      checklist_items={checklistItems}
    />
  )
}

SessionSaleDates.propTypes = {
  session_sale: React.PropTypes.shape({
    title: React.PropTypes.string.required,
    session_id: React.PropTypes.number.required,
    open_registration_start_at: React.PropTypes.string.required,
    open_registration_end_at: React.PropTypes.string.required
  }),
  currentDate: React.PropTypes.object
}

SessionSaleDates.defaultProps = {
  currentDate: moment()
}

export default connect((state) => ({
  user: currentUserSelector(state)
}))(SessionSaleDates)
