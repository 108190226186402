import React from 'react'

import moment from 'moment'

import { applyHOCs, formatDate, formatPrice } from 'helpers'

import { Pricing } from '../Pricing'
import { connect } from 'react-redux'
import { currentUserSelector } from 'auth'

export const SessionSalePricing = ({ session_sale: sessionSale }) => {
  if (!sessionSale) return null

  const pricing = {
    item_name: sessionSale.title,
    item_price: formatPrice(sessionSale.reservation_price),
    item_unit: 'Per Faculty Member',
    item_details: [`Registration Ends ${formatDate(sessionSale.open_registration_end_at)}`]
  }

  if (sessionSale.eligible_discount_windows?.length > 0) {
    pricing.item_details = sessionSale.eligible_discount_windows?.map((discountWindow) => {
      return `${discountWindow.name} Discount Ends: ${formatDate(discountWindow.end_at)}`
    })
    pricing.item_discounted_price = formatPrice(sessionSale.discounted_price)
  }

  return (
    <Pricing
      title='What Is the Tuition?'
      items={[pricing]}
    />
  )
}

SessionSalePricing.propTypes = {
  session_sale: React.PropTypes.shape({
    open_registration_end_at: React.PropTypes.string.required,
    reservation_price: React.PropTypes.number.required,
    title: React.PropTypes.string.required
  }),
  currentDate: React.PropTypes.object
}

SessionSalePricing.defaultProps = {
  currentDate: moment()
}

export default applyHOCs([
  connect(
    (state) => ({
      user: currentUserSelector(state)
    })
  )
])(SessionSalePricing)
